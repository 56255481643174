// Here you can add other styles
@font-face {
    font-family: 'Avenirnext';
    src: url('../Fonts/AvenirNext-DemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avenirnext';
    src: url('../Fonts/AvenirNext-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avenirnext';
    src: url('../Fonts/AvenirNext-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avenirnext';
    src: url('../Fonts/AvenirNext-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Avenirnext';
    src: url('../Fonts/AvenirNext-UltraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }